import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Icon from '../../components/Icon'
import { H4, P, SmallText } from '../../styleElements'
import mq from '../../utils/mediaQuery'

const PressCard = ({ name, img, desc, link, ...rest }) => {
  return (
    <Root>
      <Header>
        <ImageWrapper href={link} target="_blank">
          <Img fluid={img} objectFit="cover" objectPosition="50% 50%" />
        </ImageWrapper>
        <ContentWrapper>
          <Content>{desc}</Content>
          <Name>{name}</Name>
        </ContentWrapper>
      </Header>
      <Footer>
        <LinkButton href={link} target="_blank">
          <LinkLable>Read</LinkLable>
          <Icon name="next" size={24} />
        </LinkButton>
      </Footer>
    </Root>
  )
}

PressCard.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  desc: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

const Root = styled.article`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${props => props.theme.color.gray2};
  padding: 20px 0;
  min-height: 240px;

  ${mq.md} {
    width: 30%;
  }
`

const Header = styled.header`
  display: flex;
  flex-grow: 1;
`

const ImageWrapper = styled.a`
  width: 90px;
  flex-shrink: 0;
`

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-left: 20px;
`

const Content = styled(P)`
  color: ${props => props.theme.color.gray1};
  padding-bottom: 10px;
`

const Name = styled(SmallText)`
  color: ${props => props.theme.color.gray1};
  font-style: italic;
  font-weight: ${props => props.theme.fontWeight.semiBold};
`

const Footer = styled.footer`
  padding-top: 10px;
  display: flex;
  flex-direction: row-reverse;
`

const LinkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkLable = styled(H4)`
  color: ${props => props.theme.color.primary1};
  margin-right: 5px;
`

export default PressCard
